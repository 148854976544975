import { Inject, Injectable, Optional } from '@angular/core';
import { APP_CONFIG, AppConfigModel } from '@trackback/ng-common';
import { DefaultPageInput, DirectionalLayoutInput, HyperlinkInput, IconInput, TextInput, WidgetInputModel, globalProperty } from '@trackback/widgets';
import { Observable, of } from 'rxjs';
import { DefaultWidgetsFactoryModel } from '../models/default-widgets-factory.model';

@Injectable()
export class DefaultWidgetsService implements DefaultWidgetsFactoryModel {

  constructor (@Optional() @Inject(APP_CONFIG) private readonly config?: AppConfigModel) { }

  createClientError(): Observable<WidgetInputModel> {
    return of({
      id: 'client-error-page',
      type: 'DefaultPage',
      mainContentCentered: true,
      mainContentWidget: {
        type: 'DirectionalLayout',
        orientation: 'vertical',
        align: {
          mainAxis: 'center',
          crossAxis: 'center'
        },
        widgets: [
          {
            type: 'Icon',
            value: 'error',
            color: 'warn',
            size: 48
          } as IconInput,
          {
            type: 'Text',
            text: 'An application error has occurred. If this error persists, please contact support@trackback.net',
            style: 'title'
          } as TextInput,
        ]
      } as DirectionalLayoutInput
    } as DefaultPageInput);
  }

  createNotFound(): Observable<WidgetInputModel> {
    return of({
      id: 'not-found-page',
      type: 'DefaultPage',
      mainContentCentered: true,
      mainContentWidget: {
        type: 'DirectionalLayout',
        orientation: 'vertical',
        align: {
          mainAxis: 'center',
          crossAxis: 'center'
        },
        widgets: [
          {
            type: 'Icon',
            value: 'error',
            color: 'warn',
            size: 48
          } as IconInput,
          {
            type: 'Text',
            text: 'This is not the page you are looking for',
            style: 'title'
          } as TextInput,
        ]
      } as DirectionalLayoutInput
    } as DefaultPageInput);
  }

  createServerError(): Observable<WidgetInputModel> {
    return of({
      id: 'server-error-page',
      type: 'DefaultPage',
      mainContentCentered: true,
      mainContentWidget: {
        type: 'DirectionalLayout',
        orientation: 'vertical',
        align: {
          mainAxis: 'center',
          crossAxis: 'center'
        },
        widgets: [
          {
            type: 'Icon',
            value: 'error',
            color: 'warn',
            size: 48
          } as IconInput,
          {
            type: 'Text',
            text: 'Unknown Server Error',
            style: 'title'
          } as TextInput,
        ]
      } as DirectionalLayoutInput
    } as DefaultPageInput);
  }

  createUnauthenticated(): Observable<WidgetInputModel> {
    return of({
      id: 'unauthenticated-page',
      type: 'DefaultPage',
      mainContentCentered: true,
      mainContentWidget: {
        type: 'DirectionalLayout',
        orientation: 'vertical',
        align: {
          mainAxis: 'center',
          crossAxis: 'center'
        },
        widgets: [
          {
            type: 'Icon',
            value: 'lock',
            color: {
              palette: 'primary',
              hue: 900
            },
            size: 48
          } as IconInput,
          {
            type: 'Text',
            text: 'Unauthenticated',
            style: 'title',
            color: {
              palette: 'primary',
              hue: 900
            }
          } as TextInput,
          {
            type: 'Hyperlink',
            style: 'raised',
            color: 'accent',
            text: 'Sign in',
            url: `/login`,
            queryParams: {
              'redirect_after_login': globalProperty('router.state.url')
            }
          } as HyperlinkInput
        ]
      } as DirectionalLayoutInput
    } as DefaultPageInput);
  }

  createUnauthorised(): Observable<WidgetInputModel> {
    return of({
      id: 'unauthorised-page',
      type: 'DefaultPage',
      mainContentCentered: true,
      mainContentWidget: {
        type: 'DirectionalLayout',
        orientation: 'vertical',
        align: {
          mainAxis: 'center',
          crossAxis: 'center'
        },
        widgets: [
          {
            type: 'Icon',
            value: 'error',
            color: 'warn',
            size: 48
          } as IconInput,
          {
            type: 'Text',
            text: 'You do not have the permissions to access this resource',
            style: 'title'
          } as TextInput,
        ]
      } as DirectionalLayoutInput
    } as DefaultPageInput);
  }
}
