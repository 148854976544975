<div class="mat-exp-table">
  <mat-accordion displayMode="flat" multi class="mat-table">
    <section matSort class="mat-elevation-z2 mat-header-row mat-header-row-expansion">
      <span *ngFor="let title of (input.tableHeader | parse:context | async)"
        class="mat-header-cell mat-header-cell-expansion">{{title}}</span>
    </section>
    <mat-expansion-panel *ngFor="let data of input.tableData| parse:context | async">
      <mat-expansion-panel-header [collapsedHeight]="input.panelHeaderHeight | parse:context | async"
        [expandedHeight]="input.panelContentHeight | parse:context | async" class="mat-row">
        <div class="mat-cell mat-cell-expansion" *ngFor="let colName of (input.tableColumn | parse:context | async)">
          <span class="mat-cell-expansion-text">{{data[colName]}}</span>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ng-container
          *ngIf="mergeContexts(context, wrapContext(data, input?.tableDataAlias || 'expansionRow')) as mergedContext">
          <ng-container [dynamicWidget]="widgetContent" [context]="mergedContext"></ng-container>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>