import {TranslationMap} from '../models/translation-map.model';
import {ErrorModel} from '@trackback/ng-common';
import {Register} from '@trackback/ng-common';

@Register('SetLanguage')
export class SetLanguage {
  static readonly type = '[Shared.Translation] Set Language';

  constructor(public languageCode: string) {}
}

export class LoadTranslationSuccess {
  static readonly type = '[Shared.Translation] Load Translation Success';

  constructor(public languageCode: string, public translations: TranslationMap) {}
}

export class LoadTranslationFail {
  static readonly type = '[Shared.Translation] Load Translation Fail';

  constructor(public error: ErrorModel) {}
}
