import {Type} from '@angular/core';
import {AppError, DEFAULT_ERROR_TRANSLATION_KEYS} from '../models/error.model';
import {flatten, uniq} from 'lodash-es';

/**
 * Contains all registered widget for name lookup.
 *
 * Utilises the @Register decorator for propagation.
 */
export const TYPE_REGISTRY = {};
export const TYPE_GROUPS = {};

export function Register(typeName: string | string[], groups?: string | string[]): ClassDecorator {
  return function (constructor: any) {
    if (Array.isArray(typeName)) {
      typeName.forEach(tn => {
        TYPE_REGISTRY[tn] = constructor;
        addGroups(tn, groups);
      });
    } else {
      TYPE_REGISTRY[typeName] = constructor;
      addGroups(typeName, groups);
    }
    constructor.registeredAs = typeName;
  };
}

function addGroups(typeName: string, groups?: string | string[]) {
  if (Array.isArray(groups)) {
    groups.forEach(group => pushToProperty(TYPE_GROUPS, group, typeName));
  } else if (groups) {
    pushToProperty(TYPE_GROUPS, groups, typeName);
  }
}

function pushToProperty(obj, key, value) {
  if (!obj[key]) {
    obj[key] = [];
  }
  obj[key].push(value);
}

export function getType<T>(typ: string): Type<T> {
  const componentClass = TYPE_REGISTRY[typ];
  if (componentClass) {
    return componentClass;
  } else {
    throw new AppError(`widgets/unknown-type`, DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
      `No type definition available for ${typ || 'undefined'}`);
  }
}

export function getTypesInGroup(groups: string | string[]): string[] {
  if (Array.isArray(groups)) {
    return uniq(flatten(groups.map(group => getTypesInGroup(group))));
  }
  return TYPE_GROUPS[groups] || [];
}

export function isTypeInGroup(type: string, group: string) {
  return getTypesInGroup(group).includes(type);
}
