import {Action, createSelector, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import * as Actions from './layout.actions';
import {Breakpoints} from '@angular/cdk/layout';
import {LayoutSize} from '../../models/layout/layout-size.model';
import {LayoutService} from '../../services/layout.service';
import {LayoutOrientation} from '../../models/layout/layout-orientation.model';
import { Injectable } from '@angular/core';

export interface LayoutStateModel {
  activeClientId: number | null;
  orientation?: LayoutOrientation;
  size?: LayoutSize;
}

export const InitialLayoutStateModel: LayoutStateModel = {
  activeClientId: null,
  orientation: undefined,
  size: undefined
};

@State<LayoutStateModel>({
  name: 'layout',
  defaults: InitialLayoutStateModel
})
@Injectable()
export class LayoutState implements NgxsOnInit {

  @Selector()
  static getActiveClientId(state: LayoutStateModel) { return state.activeClientId; }

  @Selector()
  static getOrientation(state: LayoutStateModel) { return state.orientation; }

  @Selector()
  static getSize(state: LayoutStateModel) { return state.size; }

  @Selector([LayoutState.getSize])
  static isSizeFn(state, size: LayoutSize) {
    const fn = (sizes: LayoutSize | LayoutSize[]) => {
      if (Array.isArray(sizes)) {
        return sizes.includes(size);
      } else {
        return sizes === size;
      }
    };
    return fn;
  }

  static isSize(size: LayoutSize) {
    const fn = createSelector([LayoutState.getSize], (actualSize) => {
      return size === actualSize;
    });
    return fn;
  }

  @Action(Actions.SetActiveClientId)
  setActiveClientId ({ patchState }: StateContext<LayoutStateModel>, { clientId }: Actions.SetActiveClientId) {
    return patchState({
      activeClientId: clientId
    });
  }

  @Action(Actions.SetOrientation)
  setOrientation ({ patchState }: StateContext<LayoutStateModel>, { orientation }: Actions.SetOrientation) {
    return patchState({
      orientation
    });
  }

  @Action(Actions.SetSize)
  setSize ({ patchState }: StateContext<LayoutStateModel>, { size }: Actions.SetSize) {
    return patchState({
      size
    });
  }

  constructor(private layoutService: LayoutService) {}

  ngxsOnInit({ dispatch }: StateContext<any>): void | any {
    this.layoutService.observeBreakpoint([Breakpoints.XSmall]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('xsmall'));
     }
    });

    this.layoutService.observeBreakpoint([Breakpoints.Small]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('small'));
      }
    });

    this.layoutService.observeBreakpoint([Breakpoints.Medium]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('medium'));
      }
    });

    this.layoutService.observeBreakpoint([Breakpoints.Large, Breakpoints.XLarge]).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetSize('large'));
      }
    });

    this.layoutService.observeBreakpoint(['(orientation: landscape)']).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetOrientation('landscape'));
      }
    });

    this.layoutService.observeBreakpoint(['(orientation: portrait)']).subscribe(state => {
      if (state.matches) {
        dispatch(new Actions.SetOrientation('portrait'));
      }
    });
  }
}
