import {Store} from '@ngxs/store';
import {DEFAULT_FALLBACK_LANGUAGE_CODE, TranslationState} from '@trackback/ng-lang';
import { DateAdapter } from '@angular/material/core';
import {distinctUntilChanged} from 'rxjs/operators';

export const appInitialiserFactory = (store: Store, dateAdapter: DateAdapter<any>) =>
  async () => {

    try {
      // Update uis date locale on each language change
      store
        .select(TranslationState.getLanguageCode)
        .pipe(distinctUntilChanged())
        .subscribe(newLanguageCode => dateAdapter.setLocale(newLanguageCode || DEFAULT_FALLBACK_LANGUAGE_CODE));
    } catch (error) {
      console.error('Error during application setup', error);
    }

  };
