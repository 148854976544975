<mat-form-field class="chip-list">
  <mat-chip-list #chipList aria-label="">
    <mat-chip *ngFor="let chip of chipsInput"
              color="warn"
              [removable]="removable"
              (removed)="remove(chip)"
              [selected]="chip.valid === false">
      {{chip.label}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="{{ this.input.placeholder | parse:context | async }}"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-error *ngIf="firstFormControlError as error">
    {{error.errorMessage | parse:context | async}}
  </mat-error>
</mat-form-field>
