import * as moment from 'moment';
import { FormattedDateRelativeToExpression } from '@trackback/widgets';
import { createSimpleNamedExpressionResolver } from '../types';

export const formatDateRelativeResolver =
  createSimpleNamedExpressionResolver<FormattedDateRelativeToExpression>(
    'formatDateRelative',
    (utcDate, options) =>
      options?.otherUtcDate
        ? moment
            .utc(utcDate)
            .from(moment.utc(options?.otherUtcDate), options?.withoutSuffix)
        : moment.utc(utcDate).fromNow(options?.withoutSuffix)
  );
