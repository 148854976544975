import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {LayoutState} from './state/layout/layout.state';
import {AppLogoComponent} from './components/app-logo/app-logo.component';
import {LayoutModule} from '@angular/cdk/layout';

const DECLARATIONS = [AppLogoComponent];

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    NgxsModule.forFeature([LayoutState])
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class NgCommonModule { }
