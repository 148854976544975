import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule, Store } from '@ngxs/store';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { APP_CONFIG, AppConfigModel, getApiUrl } from '@trackback/ng-common';
import { appInitialiserFactory } from '@app/app-initialiser.factory';
import {
  ConditionalWidget,
  DataWidget,
  ForeachWidget,
  NgWidgetsModule,
  SwitchWidget,
  DataStoreWidget
} from '@trackback/ng-widgets';
import { NgLangModule, TRANSLATION_API_URL } from '@trackback/ng-lang';
import { DateAdapter } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SharedModule } from '@app/shared/shared.module';
import NavigateActionWrapper from '@app/core/utils/navigate-action-wrapper';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AuthCookieInterceptor } from '@app/core/interceptors/auth-cookie.interceptor';

import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    NgxsModule.forRoot([], { developmentMode: !environment.PRODUCTION }),
    NgxsStoragePluginModule.forRoot({
      key: [
        'translation.languageCode',
        'layout.activeClientId',
        'menu'
      ]
    }),
    NgxsRouterPluginModule.forRoot(),
    environment.PRODUCTION ? [] : NgxsReduxDevtoolsPluginModule.forRoot(),
    environment.PRODUCTION ? [] : NgxsLoggerPluginModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    NgLangModule.forRoot(),
    NgWidgetsModule.forRoot(),
    MatTooltipModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_CONFIG,
      useValue: { ...environment, API_URL: getApiUrl(location.hostname, environment.APP_ID || 0, environment.BASE_HREF || '/') }
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useFactory: (config: AppConfigModel) => ({
        duration: config.DEFAULT_NOTIFICATION_DURATION
      } as MatSnackBarConfig),
      deps: [APP_CONFIG]
    },
    {
      provide: TRANSLATION_API_URL,
      useFactory: (config: AppConfigModel) => `${config.API_PROTOCOL}://${config.API_URL}/lang`,
      deps: [APP_CONFIG]
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitialiserFactory,
      deps: [Store, DateAdapter]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthCookieInterceptor,
      multi: true
    }
  ]
})
export class AppModule {
  /**
   * Necessary to exclude the classes of structural widgets from tree-shaking in production builds.
   */
  static readonly STATIC_IMPORTS = [
    ConditionalWidget,
    DataWidget,
    DataStoreWidget,
    ForeachWidget,
    NavigateActionWrapper,
    SwitchWidget
  ];
}
