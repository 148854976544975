import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgCommonModule } from '@trackback/ng-common';
import { NgWidgetsModule } from '@trackback/ng-widgets';
import { NgChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialModule } from 'src/material-module';
import { ChartComponent } from './widgets/chart/chart.component';
import { ExpansionPanelTableComponent } from './widgets/expansion-panel-table/expansion-panel-table.component';
import { InsertRemoveTriggerComponent } from './wrapper-widgets/insert-remove-animate/insert-remove-animate.component';
import { ChipsFieldComponent } from './widgets/chips-field/chips-field.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarComponent } from './widgets/progress-bar/progress-bar.component';

@NgModule({
  declarations: [
    ChartComponent,
    ExpansionPanelTableComponent,
    ChipsFieldComponent,
    InsertRemoveTriggerComponent,
    ProgressBarComponent
  ],
  imports: [
    NgCommonModule,
    CommonModule,
    MaterialModule,
    NgWidgetsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    MatProgressBarModule
  ],
  exports: [
    NgCommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgChartsModule,
    MatDatepickerModule,
    MaterialModule,
    MatProgressBarModule
  ],
  bootstrap: [
    ChartComponent,
    ExpansionPanelTableComponent,
    ChipsFieldComponent,
    InsertRemoveTriggerComponent,
    ProgressBarComponent
  ]
})
export class SharedModule { }
