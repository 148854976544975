import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import {
  BaseWidgetComponent
} from '@trackback/ng-widgets';
import { DirectionalLayoutInput, ExpansionPanelTableInput, ExpansionPanelTableOutput } from '@trackback/widgets';

@Component({
  selector: 'tb-expansion-panel-table',
  templateUrl: './expansion-panel-table.component.html',
  styleUrls: ['./expansion-panel-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

@Register('expansionpaneltable')
export class ExpansionPanelTableComponent extends BaseWidgetComponent<ExpansionPanelTableInput, ExpansionPanelTableOutput> implements OnInit {
  // TODO: add explicit constructor

  public widgetContent: any;
  async ngOnInit() {
    super.ngOnInit();
    this.widgetContent = {
      type: 'DirectionalLayout',
      orientation: 'vertical',
      widgets: this.input.widgets
    } as DirectionalLayoutInput;
  }
}
