import { Register } from "@trackback/ng-common";
import {
  CopyToClipboard as CopyToClipboardAction,
  DeleteWidgetOutputs as DeleteWidgetOutputsAction,
  DownloadDocument as DownloadDocumentAction,
  Navigate as NavigateAction,
  OpenDialog as OpenDialogAction,
  OpenSimpleSnackbar as OpenSimpleSnackbarAction,
  Resolved,
  SaveAsXLSX as SaveAsXLSXAction,
  ScrollIntoView as ScrollIntoViewAction,
  UpdateGlobalContext as UpdateGlobalContextAction
} from "@trackback/widgets";
import { ActionDispatcher } from "../../models/action-dispatcher.model";
import { WidgetOutputUpdate } from "../../models/widget-output-update.model";
import {
  WidgetDeregistration,
  WidgetRegistration,
} from "../../models/widget-registration.model";
export class RegisterWidget {
  public static readonly type = "[Widgets] Register";

  constructor(
    public readonly widgetId?: string,
    public readonly widgetAlias?: string,
    public readonly dispatcher?: ActionDispatcher,
    public readonly initialOutput?: Record<string, any>
  ) {}
}

export class RegisterWidgets {
  public static readonly type = "[Widgets] Register Multiple";

  constructor(public readonly widgets: Array<WidgetRegistration>) {}
}

export class DeregisterWidget {
  public static readonly type = "[Widgets] Deregister";

  constructor(
    public readonly widgetId: string,
    public readonly resetOutput?: boolean
  ) {}
}

export class DeregisterWidgets {
  public static readonly type = "[Widgets] Deregister Multiple";

  constructor(public readonly widgets: Array<WidgetDeregistration>) {}
}

export class UpdateWidgetOutput {
  public static readonly type = "[Widgets] Update Output";

  constructor(
    public readonly widgetId: string,
    public readonly output?: Record<string, any>
  ) {}
}

export class UpdateWidgetOutputs {
  public static readonly type = "[Widgets] Update Outputs";

  constructor(public readonly outputs: Array<WidgetOutputUpdate>) {}
}

export class ResetWidgetOutput {
  public static readonly type = "[Widgets] Reset Output";

  constructor(
    public readonly widgetId: string,
    public readonly output?: Record<string, any>
  ) {}
}

@Register("DeleteWidgetOutputs")
export class DeleteWidgetOutputs {
  public static readonly type = "[Widgets] Delete Widget Outputs";

  constructor(public readonly payload: Resolved<DeleteWidgetOutputsAction["payload"]>) {}
}

@Register("DownloadDocument")
export class DownloadDocument {
  public static readonly type = "[Widgets] Download Document";

  constructor(public readonly payload: Resolved<DownloadDocumentAction["payload"]>) {}
}
@Register("ScrollIntoView")
export class ScrollIntoView {
  public static readonly type = "[Widgets] Scroll Into View";
  constructor(public readonly payload: Resolved<ScrollIntoViewAction["payload"]>) {}
}

@Register("UpdateGlobalContext")
export class UpdateGlobalContext {
  public static readonly type = "[Widgets] Update Global Context";

  constructor(public readonly context?: Resolved<UpdateGlobalContextAction["payload"]>) {}
}

@Register("OpenDialog")
export class OpenDialog {
  public static readonly type = "[Widgets] Open Dialog";

  constructor(public readonly payload: Resolved<OpenDialogAction["payload"]>) {}
}

@Register(["OpenSimpleNotification", "OpenSimpleSnackbar"])
export class OpenSimpleSnackbar {
  public static readonly type = "[Widgets] Open Simple Snackbar";

  constructor(public readonly payload: Resolved<OpenSimpleSnackbarAction["payload"]>) {}
}

@Register("SaveAsXLSX")
export class SaveAsXLSX {
  public static readonly type = "[Widgets] Save As XLSX";

  constructor(public readonly payload: Resolved<SaveAsXLSXAction["payload"]>) {}
}

@Register("Refresh")
export class Refresh {
  public static readonly type = "[Widgets] Refresh";

  constructor() {}
}

@Register("Navigate")
export class Navigate {
  public static readonly type = "[Widgets] Navigate";

  constructor(public readonly payload: Resolved<NavigateAction["payload"]>) {}
}

@Register("CopyToClipboard")
export class CopyToClipboard {
  public static readonly type = "[Widgets] CopyToClipboard";

  constructor(public readonly payload: Resolved<CopyToClipboardAction["payload"]>) {}
}

/**
 * Action responsible for caching the result of global actions,
 * this is because dispatched actions in NGXS do not have an outcome.
 *
 * Local or remote actions do not have this problem as they are resolved internally.
 *
 * @see [Official Docs](https://ngxs.gitbook.io/ngxs/concepts/store#dispatching-actions)
 */
export class DeclareGlobalActionResult {
  public static readonly type = "[Widgets] Declare Global Action Result";

  constructor(public readonly result: any) {}
}
