import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Select } from '@ngxs/store';
import { TranslationState } from '@trackback/ng-lang';
import { DirectionalLayoutInput, TextInput } from '@trackback/widgets';
import { Observable } from 'rxjs';

@Component({
  selector: 'tb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  @Select(TranslationState.isInitialized)
  languageInitialized$: Observable<boolean>;

  @Select(TranslationState.getLoadingError)
  translationError$: Observable<Error>;

  readonly errorWidget = {
    type: 'DirectionalLayout',
    orientation: 'vertical',
    widgets: [
      {
        type: 'Icon',
        value: 'error_outline',
        color: 'accent',
        size: 48,
        margin: {
          bottom: 15
        }
      },
      {
        type: 'Text',
        style: 'display-1',
        text: 'Oops! Something went wrong...',
        wrap: true
      } as TextInput
    ],
    align: {
      mainAxis: 'center',
      crossAxis: 'center'
    },
    layout: {
      growWeight: 1
    },
    padding: {
      left: 15,
      right: 15,
      bottom: 15,
      top: 15
    }
  } as DirectionalLayoutInput;
}
