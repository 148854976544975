import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { TextFieldInput, TextFieldOutput } from '@trackback/widgets';
import { takeUntil } from 'rxjs/operators';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';


@Register('TextField')
@Component({
  selector: 'tb-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})
export class TextFieldComponent extends BaseFormFieldWidgetComponent<TextFieldInput, TextFieldOutput> implements OnInit {
  hide: boolean;
  actionDisabled: boolean;
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @see {@link https://ux.stackexchange.com/questions/95336/how-long-should-the-debounce-timeout-be}
   */
  defaultDebounceTime() {
    return 500;
  }
  async ngOnInit() {
    await super.ngOnInit();
    this.hide = this.input.fieldType === 'password';
    if (this.input.actionDisabled) {
      this.parse(this.input.actionDisabled)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(actionDisabled => this.actionDisabled = !!actionDisabled);
    } else {
      this.actionDisabled = false;
    }
  }

  onEnter(event: KeyboardEvent) {
    if (!this.actionDisabled && this.input.enterAction) {
      this.updateOutput({ actionPending: true }, false);
      this.dispatchActionsPromise(this.input.enterAction)
        .finally(() => this.updateOutput({ actionPending: false }, false));
    }
  }
}