import { Inject, Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslationMap} from '../models/translation-map.model';
import {Observable} from 'rxjs';
import {TRANSLATION_API_URL} from '../models/translation-api-url.model';
import {TranslationApiModel} from '../models/translation-api.model';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class TranslationApiService implements TranslationApiModel {

  constructor (private readonly http: HttpClient, @Inject(TRANSLATION_API_URL) private readonly apiUrl: string,
               private readonly store: Store) { }

  getTranslations(languageCode: string, keys?: string[]) {
    return this.http.get(`${this.apiUrl}/${languageCode}?keys=${encodeURIComponent((keys || []).join(','))}`) as Observable<TranslationMap>;
  }

}

