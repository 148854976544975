/**
 * [[widgets/form-field]] for selecting date values.
 *
 * The datepicker uses the [ISO 8601](https://en.wikipedia.org/wiki/ISO_8601) format with [momentjs](https://momentjs.com/) for parsing.
 *
 * This implementation follows the [Official Specification](https://material.io/design/components/buttons.html) and
 * is based on the [Angular Material Implementation](https://material.angular.io/components/button/examples).
 *
 * @module widgets/datepicker
 * @example A simple datepicker that defaults to the same date next month
 * <pre>
 {
    id: 'example-date-picker-field',
    alias: 'date',
    type: "Datepicker",
    label: 'Date',
    defaultValue: moment().add(1, "month").toISOString()
  }
 * </pre>
 */

/** Required comment to display module description, wont be included in the documentation */

import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { Moment } from 'moment';
import * as moment from 'moment/moment';
import { map } from 'rxjs/operators';
import { BaseFormFieldWidgetComponent } from '../base-form-field-widget.component';
import { DatepickerFieldInput, DatepickerFieldOutput } from '@trackback/widgets';

const momentConstructor: (value?: any) => moment.Moment = (<any>moment).default || moment;

/**
 * @ignore
 */
@Register('DatepickerField')
@Component({
  selector: 'tb-datepicker-field',
  templateUrl: './datepicker-field.component.html',
  styleUrls: ['./datepicker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})
export class DatepickerFieldComponent extends BaseFormFieldWidgetComponent<DatepickerFieldInput, DatepickerFieldOutput> implements OnInit {
  public _minDate: Moment;
  public _maxDate: Moment;

  constructor(injector: Injector) {
    super(injector);
  }

  protected formatDefaultValue(next: any): Moment {
    return momentConstructor(next).local();
  }

  protected formatOutputValue(next: Moment): string {
    return next === null ? null : next.utc().toISOString();
  }

  async ngOnInit() {
    await super.ngOnInit();
    if (this.input.minDate) {
      this.parse(this.input.minDate).pipe(
        map(minDate => this.formatDefaultValue(minDate))
      ).subscribe(minDate => {
        this._minDate = minDate;
      });
    }
    if (this.input.maxDate) {
      this.parse(this.input.maxDate).pipe(
        map(maxDate => this.formatDefaultValue(maxDate))
      ).subscribe(maxDate => this._maxDate = maxDate);
    }
  }
}
