import { Injectable, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {NgCommonModule} from '@trackback/ng-common';
import {NgxsModule} from '@ngxs/store';
import {TranslationState} from './state/translation.state';
import {TRANSLATION_API, TranslationApiModel} from './models/translation-api.model';
import {TranslationApiService} from './services/translation-api.service';

@NgModule({
  imports: [
    CommonModule,
    NgCommonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    NgxsModule.forFeature([TranslationState])
  ],
  declarations: [],
  exports: []
})
@Injectable()
export class NgLangModule {
  static forRoot(translationService?: Type<TranslationApiModel>): ModuleWithProviders<NgLangModule> {
    return {
      ngModule: NgLangModule,
      providers: [
        {
          provide: TRANSLATION_API,
          useClass: translationService || TranslationApiService
        }
      ]
    };
  }
}
