import {Injectable} from '@angular/core';
import {getActionTypeFromInstance, NgxsPlugin, setValue} from '@ngxs/store';
import {merge} from 'lodash-es';
import {UpdateGlobalContext} from '../state/widgets/widgets.actions';

@Injectable()
export class NgxsWidgetPluginService implements NgxsPlugin {

  handle(state: any, event: any, next: (state: any, mutation: any) => any): any {
    const type = getActionTypeFromInstance(event);
    let nextState = state;

    if (type === UpdateGlobalContext.type) {
      const globalContextEvent = event as UpdateGlobalContext;
      nextState = merge({}, state, globalContextEvent.context);
      nextState = setValue(nextState, 'widgets.lastGlobalActionResult', globalContextEvent.context);
    }

    return next(nextState, event);
  }

}
