import { Directive, Injector, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { LayoutOrientation, LayoutSize, LayoutState } from '@trackback/ng-common';
import { BasePageInput, BasePageOutput, LocalActionModel, ToolbarInput, WidgetInputModel } from '@trackback/widgets';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { WidgetsState } from '../../public_api';
import { WidgetDefinitionModel, WidgetDefinitionTuple } from '../models/widget-input.model';
import { BaseWidgetComponent } from './base-widget.component';

@Directive()
export abstract class BasePageWidgetComponent<I extends BasePageInput, O extends BasePageOutput>
  extends BaseWidgetComponent<I, O> implements OnInit {

  constructor(injector: Injector) {
    super(injector);
  }

  @Select(LayoutState.getSize)
  layoutSize$: Observable<LayoutSize>;

  @Select(LayoutState.isSize('small'))
  isSmall$: Observable<boolean>;

  // @ts-ignore
  isHandheld$ = this.layoutSize$.pipe(
    // defaultIfEmpty(LayoutSize.MEDIUM),
    map(size => ['xsmall', 'small'].includes(size))
  );

  @Select(LayoutState.isSize('large'))
  isLarge$: Observable<boolean>;

  @Select(LayoutState.getOrientation)
  orientation$: Observable<LayoutOrientation>;

  protected readonly _navOpen$$ = new Subject<boolean>();
  protected readonly _sideContentOpen$$ = new Subject<boolean>();
  protected readonly _sideContentModal$$ = new BehaviorSubject<boolean>(false);

  public readonly _navWidget$$ = new BehaviorSubject<WidgetInputModel | undefined>(undefined);
  public readonly _sideContentWidget$$ = new BehaviorSubject<WidgetInputModel | undefined>(undefined);
  public readonly _toolbarWidget$$ = new BehaviorSubject<ToolbarInput | undefined>(undefined);

  public readonly _navTemplate$$ = new BehaviorSubject<Record<string, any> | undefined>(undefined);
  public readonly _sideContentContext$$ = new BehaviorSubject<Record<string, any> | undefined>(undefined);
  public readonly _toolbarTemplate$$ = new BehaviorSubject<Record<string, any> | undefined>(undefined);

  // @ts-ignore
  public readonly _toolbarHeight$ = combineLatest([this._toolbarWidget$$, this.layoutSize$, this.orientation$]).pipe(
    map(([toolbar, layoutSize, orientation]) => {
      const rowCount = toolbar ? (Array.isArray(toolbar.rows) && toolbar.rows.length) || 1 : 0;
      let sizeMultiplier = 64;
      if (layoutSize === 'small' && orientation === 'landscape') {
        sizeMultiplier = 48;
      } else if (layoutSize === 'small' || layoutSize === 'xsmall') {
        sizeMultiplier = 56;
      } else if (layoutSize === 'large') {
        sizeMultiplier = 0;
      }
      return rowCount * sizeMultiplier;
    })
  );

  public readonly _navOpen$ = this._navOpen$$.pipe(
    distinctUntilChanged()
  );
  public readonly _sideContentOpen$ = this._sideContentOpen$$.pipe(
    distinctUntilChanged()
  );
  public readonly _sideContentModal$ = this._sideContentModal$$.pipe(
    distinctUntilChanged()
  );

  // @ts-ignore
  public readonly _sidenavMode$ = this.layoutSize$.pipe(
    map(size => size === 'large' ? 'side' : 'over')
  );

  // @ts-ignore
  readonly _sideContentMode$ = combineLatest([this._sideContentModal$, this.layoutSize$]).pipe(
    map(([modal, size]) => ['medium', 'large'].includes(size) && !modal ? 'side' : 'over')
  );

  get navVisible() {
    const output = this._store.selectSnapshot(WidgetsState.getWidgetOutput(this.input.id));
    return output && output.navOpen;
  }

  hideActive() {
    this.isLarge$.pipe(takeUntil(this.destroyed$))
      .subscribe((isLarge) => {
        if (!isLarge && this.navVisible) {
          this.setNavOpen(false);
        } else {
          this.setSideContentOpen(false);
        }
      });
  }

  setNavOpen(flag: boolean) {
    this._navOpen$$.next(flag);
  }

  setSideContentOpen(flag: boolean) {
    this._sideContentOpen$$.next(flag);
  }

  handleSetNavOpenAction(action: LocalActionModel): Observable<any> {
    this.setNavOpen(action.payload as boolean);
    return of(action.payload);
  }

  handleSetSideContentOpenAction(action: LocalActionModel): Observable<any> {
    this.setSideContentOpen(action.payload as boolean);
    return of(action.payload);
  }

  handleToggleNavOpenAction(action: LocalActionModel): Observable<any> {
    const oldState = this.navVisible;
    const newState = !oldState;
    this.setNavOpen(newState);
    return of(newState);
  }

  handleSetSideContentAction(action: LocalActionModel): Observable<any> {
    const { widget, context } = action.payload as WidgetDefinitionModel;
    const contextPresent = context !== undefined;
    if (widget && contextPresent) {
      this._sideContentContext$$.next(context);
      this._sideContentWidget$$.next(widget);
      return of([widget, context] as WidgetDefinitionTuple);
    } else if (widget) {
      this._sideContentWidget$$.next(widget);
      this._sideContentContext$$.next(undefined);
      return of(widget);
    } else if (contextPresent) {
      this._sideContentContext$$.next(context);
      return of(context);
    } else {
      return of(null);
    }
  }

  handleSetNavWidgetAction(action: LocalActionModel): Observable<any> {
    this._navWidget$$.next(action.payload as WidgetInputModel);
    return of(action.payload);
  }

  handleSetToolbarWidgetAction(action: LocalActionModel): Observable<any> {
    this._toolbarWidget$$.next(action.payload as ToolbarInput);
    return of(action.payload);
  }

  handleSetNavTemplateAction(action: LocalActionModel): Observable<any> {
    this._navTemplate$$.next(action.payload as Record<string, any>);
    return of(action.payload);
  }

  handleSetToolbarTemplateAction(action: LocalActionModel): Observable<any> {
    this._toolbarTemplate$$.next(action.payload as Record<string, any>);
    return of(action.payload);
  }

  handleSetSideContentModalAction(action: LocalActionModel): Observable<any> {
    this._sideContentModal$$.next(action.payload as boolean);
    return of(action.payload);
  }

  async ngOnInit() {
    await this.parseId();
    // Add page class to all page types
    this.addStyleClasses('page');

    this._navOpen$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(navOpen => this.updateOutput({
      navOpen
    } as Partial<O>));

    this._sideContentOpen$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(sideContentOpen => {
      if (this.input.onSideContentClosedAction) {
        this.dispatchActionsPromise(this.input.onSideContentClosedAction);
      }
      this.updateOutput({
        sideContentOpen
      } as Partial<O>);
    });

    this.layoutSize$.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this.destroyed$)
    ).subscribe(([prev, next]) => {
      if (prev) {
        this.removeStyleClasses(prev);
      }
      if (next) {
        this.addStyleClasses(next);
      }
    });

    this.orientation$.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this.destroyed$)
    ).subscribe(([prev, next]) => {
      if (prev) {
        this.removeStyleClasses(prev);
      }
      if (next) {
        this.addStyleClasses(next);
      }
    });

    this._toolbarWidget$$.next(this.input.toolbarWidget as ToolbarInput);
    this._sideContentWidget$$.next(this.input.sideContentWidget);
    this._navWidget$$.next(this.input.navWidget);
    this._sideContentModal$$.next(this.input.sideContentModal || false);

    this.register({
      navOpen: false,
      sideContentOpen: false
    } as Partial<O>);
    this.init();
  }

}