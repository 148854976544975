import {Register} from '@trackback/ng-common';
import {Navigate} from '@ngxs/router-plugin';
import {NavigationExtras, Params} from '@angular/router';

@Register('Navigate')
export default class NavigateActionWrapper extends Navigate {
  static '[Router] Navigate';

  constructor(payload: NavigateActionPayload) {
    super(payload.path, payload.queryParams, payload.extras);
  }

}

export interface NavigateActionPayload {
  readonly path: any[];
  readonly queryParams?: Params;
  readonly extras?: NavigationExtras;
}
