<ng-container *ngIf="!(languageInitialized$ | async); else page">
  <ng-container *ngIf="translationError$ | async as error; else loading">
    <ng-container [dynamicWidget]="errorWidget"></ng-container>
  </ng-container>
  <ng-template #loading>
    <div style="display:flex; flex-direction: column; justify-content:center; align-items:center; height:100%;">
      <div style="color: #28232A; font-size: 24pt; font-weight: 600; font-family: sans-serif">TrackBack</div>
      <div style="position:relative; margin-top: 25px; height:18px; width:68px;">
        <div style="position:absolute; top:0; left:0; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both; -webkit-animation-delay:-0.32s; animation-delay:-0.32s;"></div>
        <div style="position:absolute; top:0; left:25px; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both; -webkit-animation-delay:-0.16s; animation-delay:-0.16s;"></div>
        <div style="position:absolute; top:0; left:50px; width:18px; height:18px; background-color:#8C0000; border-radius:100%; display:inline-block; -webkit-animation:anim 1s infinite ease-in-out both; animation:anim 1s infinite ease-in-out both;"></div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #page>
  <router-outlet></router-outlet>
</ng-template>
