import { APP_CONFIG, Register } from '@trackback/ng-common';
import { IfInputModel } from '@trackback/widgets';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetDefinitionTuple } from '../models/widget-input.model';
import { ParserService } from '../services/parser.service';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from './index';
import { WidgetResolver } from './widget-resolver';

@Register('if', STRUCTURAL_WIDGET_TYPE_GROUP)
export class ConditionalWidget extends WidgetResolver<IfInputModel<any, any>> {

  private readonly _parser = this._injector.get(ParserService);
  private readonly _config = this._injector.get(APP_CONFIG, null);

  getState(context?: Record<string, any>): Observable<WidgetDefinitionTuple[]> {
    return this._parser.parse(this._input.condition, {
      context: context,
      log: !this._config || !this._config.PRODUCTION ? console.log : undefined,
    }).pipe(
      map(result => result ? this._input.thenWidget : this._input.elseWidget),
      map(widget => [[widget, context] as WidgetDefinitionTuple])
    );
  }

}
