/**
 * This implementation follows the ng2 Chart (https://valor-software.com/ng2-charts/#LineChart)
 * using below version
 * "chart.js": 2.8.0
 * "ng2-charts": 2.2.3
 */

import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@trackback/ng-common';
import { BaseWidgetComponent } from '@trackback/ng-widgets';
import { ChartInput, ChartOutput } from '@trackback/widgets';
import type { ChartOptions } from 'chart.js';
import { set } from 'lodash-es';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tb-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@Register('chart')
export class ChartComponent extends BaseWidgetComponent<
  ChartInput,
  ChartOutput
> {
  static readonly DEFAULT_COLORS = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(161, 10, 40, 0.5)',
    },
  ];

  static readonly DEFAULT_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
  };

  static readonly DEFAULT_DATASET_CONFIGURATION = {
    fill: true,
    tension: 0.2
  };

  public dataWithColors$ = this.input$.pipe(
    switchMap((input) => this.parse(input.data)),
    map((data: any[]) =>
      data.map((row, idx) => ({
        ...ChartComponent.DEFAULT_DATASET_CONFIGURATION,
        ...row,
        ...(this.input.colors || ChartComponent.DEFAULT_COLORS)[idx],
      }))
    )
  );

  public options$ = this.input$.pipe(
    switchMap((input) =>
      this.parse([
        input.legendPosition,
        input.yAxisPercentage,
        input.scaleLabelText,
      ])
    ),
    map(([legendPosition, yAxisPercentage, scaleLabelText]) => {
      const options = Object.assign({}, ChartComponent.DEFAULT_OPTIONS);

      set(options, 'plugins.legend.position', legendPosition || 'bottom');

      if (yAxisPercentage) {
        set(options, 'scales.y', {
          min: 0,
          ticks: {
            callback: (value) => value + '%',
          },
          title: {
            text: scaleLabelText,
          },
        });
      }

      return options;
    })
  );
}
