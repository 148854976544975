/**
 * @module widgets/directional-layout
 * @preferred
 */

/** Required comment to display module description, wont be included in the documentation */

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding, Injectable,
  Injector,
  OnDestroy,
  OnInit,
  TrackByFunction,
  ViewEncapsulation
} from '@angular/core';
import {BaseWidgetComponent} from '../base-widget.component';
import {Register} from '@trackback/ng-common';
import {memoize} from 'lodash-es';
import { DirectionalLayoutInput, DirectionalLayoutOutput, WidgetInputModel } from '@trackback/widgets';

@Register('DirectionalLayout')
@Component({
  selector: 'tb-directional-layout',
  templateUrl: './directional-layout.component.html',
  styleUrls: ['./directional-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class DirectionalLayoutComponent extends BaseWidgetComponent<DirectionalLayoutInput, DirectionalLayoutOutput>
  implements OnInit, OnDestroy {

  @HostBinding('style.flexWrap')
  get wrap() {
    if (this.input.wrap) {
      return 'wrap';
    } else {
      return null;
    }
  }

  @HostBinding('style.justifyContent')
  get alignMain() {
    if (this.input.align && this.input.align.mainAxis) {
      return this.input.align.mainAxis;
    } else {
      return null;
    }
  }

  @HostBinding('style.alignItems')
  get alignCrossSingleLine() {
    if (this.input.align && this.input.align.crossAxis) {
      return this.input.align.crossAxis;
    } else {
      return null;
    }
  }

  @HostBinding('style.alignContent')
  get alignCrossMultiLine() {
    if (this.input.align && this.input.align.lines) {
      return this.input.align.lines;
    } else {
      return null;
    }
  }

  constructor(injector: Injector) {
    super(injector);
  }

  @HostBinding('style.paddingTop')
  paddingTop?: string;

  @HostBinding('style.paddingRight')
  paddingRight?: string;

  @HostBinding('style.paddingBottom')
  paddingBottom?: string;

  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  createForOfContext = memoize((index, first, last, even, odd) => {
    return this.wrapContext({
      index,
      first,
      last,
      even,
      odd
    }, 'layout');
  }, (...args) => args.join());

  async ngOnInit() {
    // Skip Registration
    this.init();
    this._parser.parseOnce(this.input.orientation)
    .subscribe(data => {
      if (data === 'vertical') {
        this.addStyleClasses('vertical');
      } else {
        this.addStyleClasses('horizontal');
      }
    });
  }

  ngOnDestroy(): void {
    // Skip Deregistration
    this.destroy();
  }

  trackByWidgetId: TrackByFunction<WidgetInputModel> = (index: number, item: WidgetInputModel) => {
    return item.id || index;
  }

}