import { Observable } from 'rxjs';
import type { NamedExpression, Resolved } from "@trackback/widgets";
import { Parser, ParseOptions, DataChangeCallback, ResolverFunction, Function } from './parser';

export interface NamedResolver<
  Definition extends NamedExpression<string, any[], any>
> {
  (
    parser: Parser,
    options: ParseOptions,
    onData: DataChangeCallback<Definition['result']>,
    args: Definition['args']
  ): Function<[], void> | void;
  id: Definition['$'];
}

export type SimpleNamedExpressionResolveFunction<
  Definition extends NamedExpression<string, any[], any>
> = (...args: Resolved<Definition['args']>) => Definition['result'];

export const createNamedExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>
>(
  id: Definition['$'],
  fn: ResolverFunction<Definition['args'], Definition['result']>
): NamedResolver<Definition> => Object.assign(fn, { id });

export type NamedResolverFunction<Definition extends NamedExpression<string, any[], any>> = (
  parser: Parser,
  options: ParseOptions,
  onData: DataChangeCallback<Definition['result']>,
  args: Definition['args']
) => Function<[], void> | void;

export const createSimpleNamedExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>
>(
  id: Definition['$'],
  fn: SimpleNamedExpressionResolveFunction<Definition>
): NamedResolver<Definition> =>
  Object.assign(
    (
      parser: Parser,
      options: ParseOptions,
      onDataChange: DataChangeCallback<Definition['result']>,
      args: Definition['args']
    ) =>
      parser.parseArray(
        args,
        {
          error: onDataChange.error,
          next: (resolvedArgs) => onDataChange.next(fn(...resolvedArgs)),
        },
        options
      ),
    { id }
  );

export const createNoArgsObservableNamedExpressionResolver = <
  Definition extends NamedExpression<string, any[], any>
>(
  id: Definition['$'],
  fn:
    | Function<[], Observable<Definition['result']>>
    | Observable<Definition['result']>
): NamedResolver<Definition> =>
  Object.assign(
    (
      _parser: Parser,
      _options: ParseOptions,
      onDataChange: DataChangeCallback<Definition['result']>
    ) => {
      const subscription = (fn instanceof Observable ? fn : fn()).subscribe(
        onDataChange.next,
        onDataChange.error
      );
      return subscription.unsubscribe.bind(subscription);
    },
    { id }
  );
