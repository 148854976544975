import { Injectable, Injector } from '@angular/core';
import {
  AppError,
  DEFAULT_ERROR_TRANSLATION_KEYS,
  getType,
  isTypeInGroup,
} from '@trackback/ng-common';
import { flatten, isEqual } from 'lodash-es';
import { combineLatest, Observable, of } from 'rxjs';
import { finalize, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import {
  WidgetDefinitionTuple,
} from '../models/widget-input.model';
import { STRUCTURAL_WIDGET_TYPE_GROUP } from '../structural-widgets';
import { WidgetResolver } from '../structural-widgets/widget-resolver';
import { StructuralInputModel, WidgetInputModel } from '@trackback/widgets';

@Injectable({
  providedIn: 'root',
})
export class WidgetResolverService {
  constructor(private readonly injector: Injector) {}

  resolve(
    tuple$: Observable<WidgetDefinitionTuple>
  ): Observable<WidgetDefinitionTuple[]> {
    let activeResolver: WidgetResolver<any>;
    return tuple$.pipe(
      startWith([undefined, undefined]),
      pairwise(),
      switchMap(([[previousInput], [input, context]]) => {
        if (!isEqual(input, previousInput)) {
          if (activeResolver) {
            activeResolver.disconnect();
            activeResolver = null;
          }
          if (this.isStructuralWidget(input)) {
            activeResolver = this.createWidgetResolver(input);
            activeResolver.connect();
          }
        }
        if (activeResolver) {
          const subTuples$ = activeResolver.getState(context);
          const resolvedSubTuples$ = subTuples$.pipe(
            switchMap((subTuples) =>
              combineLatest(
                subTuples.map((subTuple) => this.resolve(of(subTuple)))
              )
            ),
            map(flatten)
          );
          return resolvedSubTuples$;
        } else {
          return of([[input, context] as WidgetDefinitionTuple]);
        }
      }),
      finalize(() => activeResolver && activeResolver.disconnect())
    );
  }

  createWidgetResolver(input?: StructuralInputModel<any>): WidgetResolver<any> {
    if (input && this.isStructuralWidget(input)) {
      try {
        const ClassConstructor = getType<WidgetResolver<any>>(input.type);
        const structuralWidgetResolver = new ClassConstructor(
          input,
          this.injector
        );
        return structuralWidgetResolver;
      } catch (e) {
        console.error(e);
        throw new AppError(
          'widgets/resolver-creation-failure',
          DEFAULT_ERROR_TRANSLATION_KEYS.APPLICATION_ERROR,
          e.developerMessage || e.message
        );
      }
    }
    return new WidgetResolver<any>(input, this.injector);
  }

  isStructuralWidget(input?: WidgetInputModel): input is StructuralInputModel<any> {
    return !!input && isTypeInGroup(input.type, STRUCTURAL_WIDGET_TYPE_GROUP);
  }
}
