import {ChangeDetectionStrategy, Component} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'tb-dynamic-widget-page',
  templateUrl: './dynamic-widget-page.component.html',
  styleUrls: ['./dynamic-widget-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicWidgetPageComponent {

  readonly widget$ = this.route.data.pipe(
    map(data => data.widget)
  );

  constructor(readonly route: ActivatedRoute) { }

}
