import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Register } from '@trackback/ng-common';
import { BaseWidgetComponent } from '@trackback/ng-widgets';
import { InsertRemoveTriggerInput, InsertRemoveTriggerOutput } from '@trackback/widgets';

@Register(['InsertRemoveTrigger', 'AnimateInsertRemove'])
@Component({
  selector: 'tb-insert-remove-animate',
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('1s', style({ opacity: 0 }))
      ])
    ])],
  templateUrl: './insert-remove-animate.component.html',
  styleUrls: ['./insert-remove-animate.component.scss']
})
export class InsertRemoveTriggerComponent extends BaseWidgetComponent<InsertRemoveTriggerInput, InsertRemoveTriggerOutput>
  implements OnInit {
  // TODO: add explicit constructor

  @HostBinding('style.paddingTop')
  paddingTop?: string;
  @HostBinding('style.paddingRight')
  paddingRight?: string;
  @HostBinding('style.paddingBottom')
  paddingBottom?: string;
  @HostBinding('style.paddingLeft')
  paddingLeft?: string;

  public isShown: boolean;

  async ngOnInit() {
    await super.ngOnInit();

    this.parse(this.input.isShown)
      .subscribe((parsedExpression: boolean) => {
        this.isShown = parsedExpression || true;
      });
  }
}
