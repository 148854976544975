import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { APP_CONFIG, AppConfigModel, LayoutState, SetActiveClientId } from '@trackback/ng-common';
import { DEFAULT_FALLBACK_LANGUAGE_CODE, LoadTranslationSuccess, TranslationMap, TranslationState } from '@trackback/ng-lang';
import { WidgetInputModel } from '@trackback/widgets';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { DEFAULT_WIDGETS_FACTORY, DefaultWidgetsFactoryModel } from '../models/default-widgets-factory.model';

@Injectable({providedIn: 'root'})
export class DefaultConfigResolverGuard implements Resolve<WidgetInputModel> {

  constructor(@Inject(APP_CONFIG) private readonly config: AppConfigModel,
              @Inject(DEFAULT_WIDGETS_FACTORY) private readonly defaultWidgetsFactory: DefaultWidgetsFactoryModel,
              private readonly http: HttpClient,
              private readonly store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WidgetInputModel> {
    let queryParams;

    function parseToJson(routeFragment) {
      const split1 = routeFragment.split('?')
        , split2 = split1[1].split('&')
        , ret = {}
      ;
      split2.forEach(j => {
        const [key, value] = j.split('=');
        ret[key] = value;
      });
      return ret;
    }

    if (route.fragment && route.fragment.includes('?')) {
      queryParams = JSON.stringify(parseToJson(route.fragment));
    } else if (route.queryParams) {
      queryParams = JSON.stringify(route.queryParams);
    }

    const languageCode = this.store.selectSnapshot(TranslationState.getLanguageCode)
      || navigator.language || DEFAULT_FALLBACK_LANGUAGE_CODE
      , activeClientId = this.store.selectSnapshot(LayoutState.getActiveClientId) || null
      , urlSplit = state.url.split('?')[0].replace('\#!\/', '/')
    ;

    return this.http.get<WidgetInputModel | [WidgetInputModel, TranslationMap, number]>(
      `${this.config.API_PROTOCOL}://${this.config.API_URL}/view-config` +
     `${urlSplit}?client=${activeClientId}&language=${languageCode}&queryParams=${queryParams}`).pipe(
      switchMap(result => {
        if (Array.isArray(result)) {
          const [config, translations, clientId] = result;

          return this.store.dispatch([
            new SetActiveClientId(clientId),
            new LoadTranslationSuccess(languageCode, translations)
          ]).pipe(
            mapTo(config)
          );
        } else {
          return of(result);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          return this.defaultWidgetsFactory.createClientError();
        } else {
          if (error.status === 401) {
            return this.defaultWidgetsFactory.createUnauthenticated();
          } else if (error.status === 403) {
            return this.defaultWidgetsFactory.createUnauthorised();
          } else if (error.status === 404) {
            return this.defaultWidgetsFactory.createNotFound();
          } else if (error.status >= 500) {
            return this.defaultWidgetsFactory.createServerError();
          } else {
            return this.defaultWidgetsFactory.createClientError();
          }
        }
      })
    );
  }

}
