import { InjectionToken } from '@angular/core';
import { WidgetInputModel } from '@trackback/widgets';
import { Observable } from 'rxjs';

export interface DefaultWidgetsFactoryModel {
  createUnauthenticated(): Observable<WidgetInputModel>;
  createUnauthorised(): Observable<WidgetInputModel>;
  createNotFound(): Observable<WidgetInputModel>;
  createServerError(): Observable<WidgetInputModel>;
  createClientError(): Observable<WidgetInputModel>;
}

export const DEFAULT_WIDGETS_FACTORY = new InjectionToken<DefaultWidgetsFactoryModel>('@trackback/ng-widgets.default-widgets-factory');
