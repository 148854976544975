import {
  Directive,
  Input,
  IterableChangeRecord,
  IterableChanges,
  IterableDiffer,
  IterableDiffers,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  TrackByFunction,
  ViewContainerRef
} from '@angular/core';
import { getType } from '@trackback/ng-common';
import { WidgetInputModel } from '@trackback/widgets';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  WidgetDefinitionTuple,
} from '../models/widget-input.model';
import { WidgetResolverService } from '../services/widget-resolver.service';
import { BaseWidgetComponent } from '../widgets/base-widget.component';

@Directive({
  selector: '[dynamicWidget]',
})
export class DynamicWidgetDirective implements OnChanges, OnDestroy {
  @Input()
  dynamicWidget: WidgetInputModel;

  @Input()
  context: Record<string, any>;

  private _destroy$ = new Subject<void>();
  private _input$ = new BehaviorSubject<WidgetInputModel | undefined>(
    undefined
  );
  private _context$ = new BehaviorSubject<Record<string, any> | undefined>(
    undefined
  );
  private _differ: IterableDiffer<WidgetInputModel> | null = null;
  private trackBy: TrackByFunction<WidgetInputModel> = (
    index: number,
    widget?: WidgetInputModel
  ) => {
    return widget && widget.id && widget.type
      ? `${widget.id}-${widget.type}`
      : widget;
  }
  constructor(
    private widgetResolver: WidgetResolverService,
    private differs: IterableDiffers,
    private container: ViewContainerRef
  ) {
    this.widgetResolver
      .resolve(combineLatest([this._input$, this._context$]))
      .pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        takeUntil(this._destroy$)
      )
      .subscribe((widgetDefinitions: WidgetDefinitionTuple[]) => {
        // Exclude undefined defintions (e.g an if with a falsy condition and no elseWidget)
        widgetDefinitions = widgetDefinitions.filter((def) => !!def[0]);

        // Check for changes in the widgets to be rendered and update accordingly
        const newInputs = widgetDefinitions.map(
          (def) => def[0] as WidgetInputModel
        );
        const componentChanges = this.checkDiffers(newInputs);
        if (componentChanges) {
          this._applyChanges(componentChanges);
        }
        // Check the contexts and update accordingly
        const widgets = this.widgets;
        for (let i = 0; i < widgets.length; i++) {
          const widget = widgets[i];
          const newContext = widgetDefinitions[i][1];
          const previousContext = widget.context;
          if (previousContext !== newContext) {
            widget.context = newContext || {};
            widget.ngOnChanges({
              context: new SimpleChange(
                previousContext,
                widget.context,
                previousContext === undefined
              ),
            });
            widget._cd.markForCheck();
          }
        }
      });
  }

  private _applyChanges(changes: IterableChanges<WidgetInputModel>) {
    changes.forEachOperation(
      (
        record: IterableChangeRecord<WidgetInputModel>,
        adjustedPreviousIndex: number,
        currentIndex: number
      ) => {
        if (record.previousIndex == null) {
          const input = record.item;
          const component =
            this.container.createComponent(getType<BaseWidgetComponent<any, any>>(input.type), {
              index: currentIndex
            });
          component.instance.input = input;
          component.instance.ngOnChanges({
            input: new SimpleChange(undefined, input, true),
          });
          component.instance._cd.markForCheck();
        } else if (currentIndex == null) {
          this.container.remove(adjustedPreviousIndex);
        } else {
          const view = this.container.get(adjustedPreviousIndex);
          if (view) {
            this.container.move(view, currentIndex);
          }
        }
      }
    );
  }

  get widgets() {
    const result = [] as BaseWidgetComponent<any, any>[];
    for (let i = 0; i < this.container.length; i++) {
      const viewRef = this.container.get(i);
      if (viewRef) {
        result.push(viewRef['_view'][8]);
      }
    }
    return result;
  }

  checkDiffers(newValue: WidgetInputModel[]) {
    if (!this._differ) {
      this._differ = this.differs.find(newValue).create(this.trackBy);
    }
    return this._differ.diff(newValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) {
      return;
    }
    if (changes.dynamicWidget) {
      this._input$.next(changes.dynamicWidget.currentValue);
    }
    if (changes.context) {
      this._context$.next(changes.context.currentValue);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
