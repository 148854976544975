import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {EventManager} from '@angular/platform-browser';
import {BreakpointObserver} from '@angular/cdk/layout';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private readonly _onResize = new Subject<{width: number, height: number}>();

  private readonly onResize$ = this._onResize.pipe(
    share()
  );

  constructor(eventManager: EventManager, private breakpointObserver: BreakpointObserver) {
    eventManager.addGlobalEventListener('window', 'resize',
      e => this._onResize.next({
        width: e.target.innerWidth,
        height: e.target.innerHeight
      }));
  }

  onResize(): Observable<{width: number, height: number}> {
    return this.onResize$;
  }

  observeBreakpoint(mediaQueries: string[], operator: 'or' | 'and' = 'or') {
    return this.breakpointObserver.observe(operator === 'and' ? mediaQueries.join(' allTruthyIn ') : mediaQueries);
  }
}
