import {Routes} from '@angular/router';
import {DefaultConfigResolverGuard, DynamicWidgetPageComponent} from '@trackback/ng-widgets';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: DynamicWidgetPageComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      widget: DefaultConfigResolverGuard
    }
  }
];
